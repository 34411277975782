import React from "react";
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
//import {Redirect} from "react-router-dom";
import {
  Col, Row, Alert,
  Button, message, Spin,
  Select, Input, Form,
  Card, Modal, Table
} from "antd";
import IconWithTextCard from "./componentes/IconWithTextCard";
import btnActionStart from "./componentes/btnActionStart";
import FichajeMensual from "./componentes/FichajeMensual";
import FichajeLog from "routes/RRHH/Fichaje/FichajeLog";

import {
  selectInfoDash,
  insertFichaje,
  getLastFichaje,
  saveDraft,
  getReasonPause
} from "routes/api/urls";
import { post, get } from "routes/api/Llamadas";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    xs: { span: 4 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};
const formItemLayoutSelect = {
  labelCol: {
    xs: { span: 10 },
    sm: { span: 10 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

const mapStateToProps = state => ({
  session: state.settings.session
});
/*
  TODO:
    Modular modales
    Seguir comentando código
    Crear componentes funcionales
    quitar asignaciones de state innecesarias
      -> Seguir comprobando velocidad (Funcion Time afectando por Settimeout?)
    Crear componentes nuevos
      Envio de informacion mediante props
      Comprobar token en App.index y enviar la informacion por un prop y podríamos comprobar sesion En App.indx
    Este codigo lo entiende solo dios y yo, y cuando duro mas de 2 semanas sin verlos ya no lo entiendo yo, seguir agregando comentarios y seguir rezando
    Simplificamos una llamada con FichajesLog
*/
class Dashboard extends React.Component {
  constructor(props) {
    debugger;
    super(props);
    this.state = {
      usuario: {},
      empleado: null,
      empleado_id: this.props.session.empleado_id
        ? this.props.session.empleado_id
        : "",
      inicioJ: null,
      infoDash: this.props.session.employee,
      calculadaH:null,
      calculadaM:null,
      recuento: [],
      recuentomensual: null,
      ultimo: {},
      tipoProyectos: null,
      fichajes: null,
      loading: true,
      parcial: false,
      sumaTiempoTotales: 0,
      visible: false,
      mostrarMD: false,
      pendObserv: true,
      pendOtro: true,
      observaciones: "",
      fecha: "",
      hora: "",
      tipo_fichaje: "",
      horasRealizadas: "",
      tiempoPrevisto: "",
      empleadoXproyectoXhorario: null,
      showErrorHoras: false,
      actions: null,
      titlebtn: "",
      cardColor: "",
      iconb: "",
      lineasOrd: null,
      mostrar: false,
      horasProyect: 0,
      proyectselected: "Select",
      tiempo_acumulado: "",
      typehour: "Ord",
      typetask: "",
      proyectDisab: null,
      proyectoSelecc: null,
      subtarea:null,
      cantidadhoras:null,
      tipoProyectos:null,
      nombreProy: null,
      tipoHorasM:null,
      tipoTareasM: null,
      tareasProy: null,
      tipoHProy: null,
      unauthorized: null,
      TiempoApi: null,
      calculaTemp: [],
      dataSource: [],
      declaradas: 0,
      declaradasOrd: 0,
      declaradasJus: 0,
      pausas: 0,
      imputable:0,
      tiempoTotal: 0,
      errorObserv: false,
      proyectDef: null,
      outHour: false,
      inicioJornada:this.props.session.inicioJornada,
      duracionJornada: this.props.session.duracionJornada,
      horaJornada: this.props.session.horaJornada,
      jornadaCumplida : null,

      btnActionPlay: null,
      btnActionPause: null,
      btnActionStop: null,
      btnColorPlay: null,
      btnColorPause: null,
      btnColorStop: null,
      btnTitlePlay: 'Iniciar jornada',
      btnTitlePause: 'Pausar jornada',
      btnTitleStop: 'Detener jornada',
      visiblePausa: false,
      motivoPausa : "",
      motivoPausaText: "",
      motivosPausa : [],
      tiempoAcumulado_ : ""
    };

    this.iniciarJornada.bind(this);
    this.detenerJornada.bind(this);
    this.detieneJornada.bind(this);
    this.agregarTarea.bind(this);
    this.cachear.bind(this);
    this.pausarJornada.bind(this);
    this.reiniciarJornada.bind(this);
    this.chooseAction.bind(this);
    this.handleSelectChangeProyect.bind(this);
    this.listarMotivosPausa(this)
  }

  async componentDidMount() {
    debugger;

    this.setState({loading:true});//mascara para mostrar cargando
    
    await this.tick(); // empezamos a solicitar toda la informacion
    this.timerID = setInterval(() => this.time(), 8000); //cada 8000 ms se ejecuta la comprobacion de jornada para el boton
  }
  time() {
    let timex = moment(moment(), "HH:mm");
    let  tipo_fichaje = null; 
    let fecha = null;
    let hora   = null;
    this.setState({loading: true});
    if( this.state.ultimo ){
      hora = this.state.ultimo.hora;
      fecha = this.state.ultimo.fecha;
      tipo_fichaje = this.state.ultimo.tipo_fichaje;
    }

    let { iniciado, horaInicia } = this.state.inicios;
    let { horaJornada , duracionJornada, tiempoTotal } = this.state;

    const entrada = moment(horaJornada, "HH:mm");
    let titlebtn = null;
    let horaCalc = null;
    let fechaCalc = null;
    let tiempoAcumulado = null;

    // inclusion de conteo de jornada actualizacion de boton

      let ultimoDate = moment( fecha + " " + hora ).toDate();
      let tiempo_acumulado = moment.duration(moment() - ultimoDate).asMinutes();
      tiempo_acumulado = Math.floor(tiempo_acumulado);
      // let fecha =  moment().format("YYYY-MM-DD");
      // let hora = moment().format("HH:mm");
      //CALCULAR LA SUMA DE TIEMPOS ACUMULADOS DESDE EL ULTIMO INICIO DE JORNADA
      let suma_acumulados = tiempo_acumulado;


      let i = tipo_fichaje;
      //console.log("este es i: "+i);
      if(tipo_fichaje === "Fin de Jornada"){
        suma_acumulados = 0;
      }else{
        if(this.state.ultimo && tipo_fichaje != "Inicio de Jornada" && tipo_fichaje != "Inicio de Pausa" && this.state.ultimo.tiempoAcumulado >= 0) {
            suma_acumulados += Math.floor(this.state.ultimo.tiempoAcumulado);
            //i = x.tipo_fichaje;
          }
        };
      //console.log(suma_acumulados);

    // fin inclusion conteo de jornada actualizacion de boton 
    //  switch (tipo_fichaje){
    //   case "Fin de Pausa":
    //     horaCalc = hora;
    //     fechaCalc = fecha;
    //     break;
    //   default :
    //    horaCalc = hora;
    //    fechaCalc = fecha;
    //    break;
    //}
    // Segun el switch sacamos que fecha tomaremos, si la de inicio de jornada, o la de Fin de pausa, para contabilizar en el boton
    // revisar, estamos asignando lo mismo
    // let fechaUltimo = moment(
    //   fechaCalc + " " + horaCalc
    //  ).toDate();

    switch (tipo_fichaje){
      case "Fin de Pausa":
        tiempoAcumulado = Math.floor(suma_acumulados);
        break;
      default :
        tiempoAcumulado = moment.duration(moment() - ultimoDate).asMinutes();
        break;
    }
    //debugger;
    if(this.state.fichajes ){
      this.state.fichajes.map(x => {
        if (i != "Inicio de Jornada" && i != "Inicio de Pausa" && x.tiempoAcumulado > 0) {
          suma_acumulados += Math.floor(x.tiempoAcumulado);
          i = x.tipo_fichaje;
        }
      });
    }
    //tiempoAcumulado = suma_acumulados + tiempoAcumulado; // innecesario? redundancia ?
    //suma_acumulados = 511
    let jornadaCumplida = suma_acumulados >= 510 ? true : false;

    let horas = Math.floor(suma_acumulados / 60);
    let minutos = Math.floor(suma_acumulados % 60);
    if(minutos < 10){
      minutos = 0+""+minutos
    }
    tiempoAcumulado = horas+":"+minutos;

    const hoy = moment().format("YYYY-MM-DD");
    let closed = null;
    let parcial = null;
    let color = "grey";
    if(hoy === fecha && hoy === iniciado && tipo_fichaje === "Fin de Jornada"  ){
      closed = true;
      parcial = false
    } else if( fecha === iniciado && tipo_fichaje === "Fin de Jornada") {
      parcial = false;
      closed = false;
    }else if(!iniciado){
      parcial = false;
    }else{
      parcial = true;
      closed = false;
    }
    //comprobamos si la hora actual es antes de la entrada configurada para el empleado
    let outHour = moment(timex).isBefore(entrada);
    switch (tipo_fichaje) {
      case "Inicio de Jornada":
          titlebtn = "Detener Jornada - " + tiempoAcumulado
        break;
      case "Fin de Jornada":
          titlebtn = outHour ? "Fuera de Horario" : "Iniciar Jornada"
        break;
      case "Inicio de Pausa":
          titlebtn = "Reiniciar Jornada - " + tiempoAcumulado
        break;
      case "Fin de Pausa":
            titlebtn = "Detener Jornada - " + tiempoAcumulado
          break;
      default:
          titlebtn = "Iniciar Jornada"
        break;
    }
    
    let titleRaw = titlebtn.split(' -');
    titleRaw = titleRaw[0];
    let actionChoose = this.chooseAction;
    if(!closed){
      if (!outHour && titleRaw !== 'Detener Jornada'){
        color = "orange";
        
      }else if (outHour && (titleRaw === 'Iniciar Jornada' ||  titleRaw === 'Fuera de Horario' )){
        color = "grey";
        outHour = true;
        titlebtn = "Fuera de Horario";
      } else if(!jornadaCumplida){
        color = "teal";
      }else if (jornadaCumplida){
        color = "orange";
        actionChoose = this.detieneJornada
      }
    }else{
      color = "grey";
    }
    let button = {};
    switch (tipo_fichaje) {
      case "Inicio de Jornada":
        button = {
          titlebtn: titlebtn,
          cardColor: color,
          iconb: "fas fa-times",
          actions: actionChoose
        };
        break;
      case "Fin de Jornada":
        button = {
          titlebtn: titlebtn,
          cardColor: color,
          iconb: outHour ? "fas fa-times" : "fas fa-play",
          actions: closed ? null :  outHour ? null : this.iniciarJornada
        };
        break;
      case "Inicio de Pausa":
        button = {
          titlebtn: titlebtn,
          cardColor: color,
          iconb: "fas fa-play",
          actions: closed ? null : this.reiniciarJornada
        };
        break;
      case "Fin de Pausa":
          button = {
            titlebtn: titlebtn,
            cardColor: color,
            iconb: "fas fa-times",
            actions: closed ? null : actionChoose
          };
          break;
      default:
        button = {
          titlebtn: titlebtn,
          cardColor: color,
          iconb: "fas fa-play",
          actions: closed ? null :  outHour ? null : this.iniciarJornada
        };
        break;
    }
    this.setState({...button})
    this.setState({
      timex,
      parcial: parcial,
      outHour,
      loading: false,
      jornadaCumplida
    });

    
    //  ------------- Nueva Botonera ------------------
    console.log(tiempoAcumulado)

    /*
      btnActionPlay: null,
      btnActionPause: null,
      btnActionStop: null,
      btnColorPlay: null,
      btnColorPause: null,
      btnColorStop: null
    */
       //Si se inicio la jornada el boton de play debe estar deshabilitado

       //Si el tiempo acumulado es mayor o igual a 510 se puede dar como jornada cumplida, se debe deshabilitar el pausar y solo se podra detener jornada

       console.log(tipo_fichaje)
       console.log(outHour)
       console.log(suma_acumulados)
       this.setState({ tiempoAcumulado_ : tiempoAcumulado})

       switch (tipo_fichaje) {
        case "Inicio de Jornada":
          // Deshabilitar play
          // Habilitar Pausa solo tiene menos de 509 o menos acumulado
          // Habilitar Stop solo tiene 510 o mas acumulado
          
          this.setState({
            btnActionPlay: null,
            btnColorPlay: "grey",
            btnTitlePlay: 'Reiniciar Jornada',

            btnActionPause: (suma_acumulados >= 420)? null : this.showModalPausa,
            btnColorPause: (suma_acumulados >= 420)? "grey" : "orange",
            btnTitlePause: 'Pausar Jornada',

            //btnActionStop: (suma_acumulados >= 510)? this.showModal : null,
            btnActionStop: (suma_acumulados >= 420)? this.detieneJornada : null,
            btnColorStop: (suma_acumulados >= 420)? "red" : "grey",
            btnTitleStop: 'Detener jornada',
          });
      
          break;
        case "Fin de Jornada":
          // Habilitar play solo si no es 
          // Deshabilitar Pausa
          // Deshabilitar Stop

          this.setState({
            btnActionPlay: (outHour == false)? this.iniciarJornada : "grey",
            btnColorPlay: (outHour == false)?  "teal" : "grey",
            btnTitlePlay: 'Iniciar Jornada',

            btnActionPause: null,
            btnColorPause: "grey",
            btnTitlePause: 'Pausar Jornada',

            btnActionStop: null,
            btnColorStop: "grey",
            btnTitleStop: 'Detener Jornada',
          });
          break;
        case "Inicio de Pausa":
          // Habilitar boton de pausa debido a que la suma acumulado es menor a 510
          // Deshabilitar Stop
          this.setState({
            btnActionPlay: this.reiniciarJornada,
            btnColorPlay: "teal",
            btnTitlePlay: 'Reiniciar jornada',

            btnActionPause: "grey",
            btnColorPause: "grey",
            btnTitlePause: 'Pausar Jornada',

            //btnActionStop: (suma_acumulados >= 510)? this.showModal : null,
            btnActionStop: (suma_acumulados >= 420)? this.detieneJornada : null,
            btnColorStop: (suma_acumulados >= 420)? "red" : "grey",
            btnTitleStop: 'Detener jornada',
          });
          break;
        case "Fin de Pausa":
          this.setState({
            btnActionPlay: (outHour == false)? "grey" : this.iniciarJornada,
            btnColorPlay: (outHour == false)? "grey" : "teal",
            btnTitlePlay: 'Reiniciar jornada',

            btnActionPause: this.showModalPausa,
            btnColorPause: "orange",
            btnTitlePause: 'Pausar Jornada',

            //btnActionStop: (suma_acumulados >= 510)? this.showModal : null,
            btnActionStop: (suma_acumulados >= 420)? this.detieneJornada : null,
            btnColorStop: (suma_acumulados >= 420)? "red" : "grey",
            btnTitleStop: 'Detener jornada',
          });
            break;
        case null:
          this.setState({
            btnActionPlay: (outHour == false)? this.iniciarJornada : "grey",
            btnColorPlay: (outHour == false)?  "teal" : "grey",
            btnTitlePlay: 'Iniciar Jornada',

            btnActionPause: null,
            btnColorPause: "grey",
            btnTitlePause: 'Pausar Jornada',

            btnActionStop: null,
            btnColorStop: "grey",
            btnTitleStop: 'Detener Jornada',
          });
          this.setState({ tiempoAcumulado_ : '00:00'})
        default:
          button = {
            titlebtn: titlebtn,
            cardColor: color,
            iconb: "fas fa-play",
            actions: closed ? null :  outHour ? null : this.iniciarJornada
          };
          break;
      }

    //  ------------- Fin Nueva Botonera --------------
  }

  
  async tick() {
    //rastreamos toda la informacion necesaria para el dashboard y el control del usuario
    const { auth_token, empleado_id, infoDash } = this.props.session;
    this.setState({ loading: true }); 
    let todo = await post({empleado_id}, selectInfoDash, auth_token);
    this.setState({ loading: true }); 
    let donot = true;
    if( todo.message === "Unauthenticated."){
      localStorage.clear();
      this.props.session.isLoggedIn = true;
      this.props.session.auth_token = null;
      window.location.href = "/signin"
    }
    let current = new Date();
    let tiempoApi = this.state.tiempoApi;//comprobacion para tiempos de API, usamos la informacion cacheada si han pasado menos de 5 minutos 
    if(tiempoApi){
      let lastCall = tiempoApi.getTime();
      let actuCall = current.getTime();
      if(lastCall< actuCall){
        this.setState({ loading: true }); 
        await this.calculaJornada();
      }
    }else{
      this.setState({ loading: true }); 
      await this.calculaJornada();
    }
    //agregar borrador cache si localstorage no tiene datasource
    debugger;
    let dataSource = window.localStorage.getItem("dataSource")// ? window.localStorage.getItem("dataSource") : todo.data.borrador ? todo.data.borrador.linea : null;
    dataSource = dataSource ?  JSON.parse(dataSource) : null ;
    if(todo.error){
       donot = false
    }
    try {
      if(donot){
        let declaradasOrd = 0;
        let declaradasJus = 0;
        if(dataSource){
          dataSource.map(x => {
            if (x.typehour === 'Ord') {
              declaradasOrd = parseFloat(declaradasOrd)+parseFloat(x.Horas);
            }else if (x.typehour === 'Just') {
              declaradasJus = parseFloat(declaradasJus)+parseFloat(x.Horas);
            }
          });
        }
        this.setState({
          empleadoXproyectoXhorario: null, 
          empleadoXClienteProyect :null,
          tipoProyectos: todo.data.proyectos,
          tipoHorasM: todo.data.tipo_horas,
          tipoTareasM: todo.data.tipo_tareas,
          proyectDef: todo.data.proyecDef,
          declaradasOrd,
          declaradasJus,
          dataSource,
          horaJornada: todo.data.inicioJornada,
          duracionJornada: parseFloat(todo.data.duracionJornada),
        });
        this.handleSelectChangeProyect(todo.data.proyectos[0].id);
      }
    } catch (e) {
      console.error(e);
    }
  }

  agregarTarea = async (idArr) => {
    //se agrega una tarea a la imputacion de horas (Detalle de tareas)
    let borrador = this.state.dataSource;
    borrador = borrador.find(element => element.id == idArr);
    let ultimo_fichaje = this.state.ultimo;
    let fechaUltimo = moment(
      ultimo_fichaje.fecha + " " + ultimo_fichaje.hora
    ).toDate();

    let tiempo_acumulado = moment.duration(moment() - fechaUltimo).asMinutes();
    
    //CALCULAR LA SUMA DE TIEMPOS ACUMULADOS DESDE EL ULTIMO INICIO DE JORNADA
    let suma_acumulados = tiempo_acumulado;
    let i = this.state.ultimo.tipo_fichaje;

    this.state.fichajes.map(x => {
      if (i != "Inicio de Jornada" && x.tiempoAcumulado >= 0) {
        suma_acumulados += Math.floor(x.tiempoAcumulado);
        i = x.tipo_fichaje;
      }
    });
    if(borrador.typehour === 'Ord') borrador.errorObserv = false;
    this.setState({
      sumaTiempoTotales: Math.floor(suma_acumulados), // en minutos
      visible: true,
      tiempo_acumulado: tiempo_acumulado,
      horasRealizadas: Math.floor(suma_acumulados), // en minutos
      fecha: moment().format("YYYY-MM-DD"), // a usar en el momento del submit
      hora: moment().format("HH:mm"), // a usar en el momento del submit
      proyectDisab: idArr+"-"+borrador.nombreProyecto,
      proyectoSelecc: borrador.nombreProyecto,
      cantidadhoras: borrador.Horas,
      subtarea: borrador.subtarea,
      typetask: borrador.typetask,
      observaciones: borrador.observaciones,
      typehour: borrador.typehour,
      errorObserv: borrador.errorObserv
    });
  };
  agregar = async () => {
    //se agrega una tarea desde los 2 imput principales, se valida que tenga horas suficientes
    //se comprueba que no se exceda y se asignan las horas,tambien se precarga la sesion con la informacion enviada
    //esta funcion se utiliza tambien cuando se agrega un detalle de tarea, y se edita la pantalla, comprobamos de nuevo las horas disponibles
    //controlamos el comportamiento del usuario hasta un 85% (perfecto, me gustaria mas)
    this.setState({ loading: true });
    let current = new Date();
    let tiempoApi = this.state.tiempoApi;
    let calculada = null;
    let horasIn = this.state.horasProyect
    let decima = 0;


    if(isNaN(horasIn) || horasIn == 0 || horasIn > 10){
      this.setState({horasProyect: 0, loading: false});
    }else{
      if(tiempoApi){
        let lastCall = tiempoApi.getTime();
        let actuCall = current.getTime();
        if(lastCall< actuCall){
          //console.log("Ya pasaron 5 minutos");
          this.setState({ loading: true });
          calculada = await this.calculaJornada();
        }else{
          calculada = this.state.calculaTemp[0];
        }
      }else{
        this.setState({ loading: true });
        calculada = await this.calculaJornada();
      }
      let dataSource = this.state.dataSource ? this.state.dataSource : [];
      let cach = dataSource.length;
      let declaradas = 0;
      let { declaradasJus , declaradasOrd, typetask, observaciones, subtarea, typehour, proyectselected, errorObserv } = this.state;
      let indice = 1;
      if(cach > 0){
        dataSource.map(x => {
          if(x.typehour === "Ord" || x.typehour === "Aus"){
            declaradas += parseFloat(x.Horas);
          }
          indice = x.id +1;
        });
      }

      declaradas += parseFloat(horasIn);
      let minutFloat = (parseFloat(calculada.minutos)/60)

      if((horasIn > (calculada.horas+ minutFloat)) || (declaradas > (calculada.horas + minutFloat))){
        dataSource.push({"id":indice,  "nombreProyecto":this.state.nombreProy,
                        "Horas":horasIn, "typehour" : "Just",
                        "typetask":typetask, "subtarea":subtarea,"observaciones":observaciones,"tipoTareas":this.state.tareasProy, "tipoHoras":this.state.tipoHProy, 
                        "idProyect" : proyectselected, "errorObserv": errorObserv});
                        declaradasJus += parseFloat(horasIn);
        message.error(`No tienes horas Ordinarias, comprueba el reloj del botón`);
      }else{
        dataSource.push({"id":indice,  "nombreProyecto":this.state.nombreProy,
                        "Horas":horasIn, "typehour":typehour ?  typehour : "Ord",
                        "typetask":typetask, "subtarea":subtarea,"observaciones":observaciones,"tipoTareas":this.state.tareasProy, "tipoHoras":this.state.tipoHProy,
                        "idProyect" : proyectselected,  "errorObserv": errorObserv});
                        typehour ? (typehour === "Ord" || typehour === "Aus") ? declaradasOrd += parseFloat(horasIn) : declaradasJus += parseFloat(horasIn): declaradasOrd += parseFloat(horasIn) ;
      }

      window.localStorage.setItem("dataSource", JSON.stringify(dataSource));
      this.setState({
        dataSource : dataSource,
        horasProyect : 0,
        declaradas,
        declaradasJus,
        declaradasOrd,
        loading: false
      })
    }
  };
  changehour = (event) => {
    //manejo de cambio de inputs de hora, usamos una expresion regular 
    // con un entero de 0 a 9 seguido de un Punto (.) y el unico decimal admitido es el 5 
    var re = /^([0-8]{1}(\.[5]{1})?)$/; 
    let inputHour = event.target.value;
    let comprobacion = inputHour.split(".");
    
    if (comprobacion.length > 1 && comprobacion[1] === ""){
      this.setState({ horasProyect: event.target.value })
    }
    if(inputHour === ""){
      this.setState({ horasProyect: event.target.value })
    }
    var OK = re.exec(inputHour);
    if (OK) {
      this.setState({ horasProyect: event.target.value })
    }
  }
  calchora = (val) => {
    //esta funcion la debemos eliminar, teniamos problemas con la asignacion del state, se coloco al principio y no hemos refactorizado por la falta de tiempo
    this.setState({horasProyect: val});
  }
  handleSelectChangeProyect = (value) => {

    const { tipoProyectos, tipoHorasM, tipoTareasM } = this.state;
    let horasTipo = null;
    let tareasTipo = null;

    let proyectoTipo = tipoProyectos.find(x => x.id === parseInt(value));
    let proyectoId = proyectoTipo.id;
    proyectoId = proyectoId.toString()
    let clienteId = proyectoTipo.client_id;
    let horasTipoPro = tipoHorasM.filter(x => x.pro === proyectoId);
    let horasTipoCli = tipoHorasM.filter(x => x.cli === clienteId);
    //let tareasTipoPro = tipoTareasM.filter(x => x.pro === proyectoId);
    //let tareasTipoCli = tipoTareasM.filter(x => x.cli === clienteId);
    
    horasTipo = horasTipoPro.length > 0  ? horasTipoPro : horasTipoCli;
    //tareasTipo = tareasTipoPro.length > 0 ? tareasTipoPro : tareasTipoCli;

    this.setState({ proyectselected: proyectoTipo.id, nombreProy:  proyectoTipo.nombre,  tipoHProy: horasTipo /*,tareasProy: tareasTipo*/ });
  }
  handleSelectChangeTypeH = (value) => {
    //cambio de tipos de hora dentro de la modal detalle de tarea
    this.setState({typehour:value});
  }
  handleSelectChangeTypeTsk = (value) => {
    this.setState({typetask:value});
  }

  handleOk = async () => {
    //asigna  la informacion precacheada en modal de registro detalle tareas

    this.setState({loading: true});
    let idArr = this.state.proyectDisab;
    idArr = idArr.split('-');
    idArr = idArr[0];
    let { declaradasOrd, declaradasJus, cantidadhoras, typetask, observaciones, typehour} = this.state;
    let errorObserv = false;
    let decima = 0;
    if(cantidadhoras.length > 1){
      decima = cantidadhoras.split(',');
      if(decima.length > 1){
        cantidadhoras = decima[0]+'.'+decima[1];  
      }
    }
    const cantint = parseFloat(cantidadhoras);
    await this.calchora(cantint)// esto deberia eliminarse, no me funcionaba el setstate
    let proy = this.state.dataSource;
    let edit = proy.splice(proy.findIndex(({id}) => id == idArr), 1)[0];
    debugger;
    await this.handleSelectChangeProyect(edit.idProyect);
    // mas validaciones, comprobar que las horas pendientes de justificar tengan observaciones
    
    if (typehour === 'Just' || typehour === 'Aus') {

      if(!observaciones ){

        errorObserv = true;
        edit.errorObserv = true;
        proy.push(edit);
        this.setState({errorObserv : true, loading:false, typehour: typehour});
        return false;
      }
        this.setState({errorObserv : false, loading:false, typehour: typehour});
        if(edit.typehour === 'Ord' || edit.typehour === 'Aus'){

          declaradasOrd = parseFloat(declaradasOrd)-parseFloat(cantint);
        }else{
          declaradasJus = parseFloat(declaradasJus)-parseFloat(cantint);
        }
    }else{

    }

    if(!errorObserv){
      this.setState({typetask : typetask, observaciones: observaciones, typehour: typehour, declaradasOrd, declaradasJus, errorObserv : false})
      await this.agregar();
      this.setState({
        visible: false,
        cantidadhoras: null,
        subtarea: null,
        typetask: "",
        observaciones: null,
        typehour: null,
        loading: false
      });
    }
  };
  handleCancel = () => {
    //borra la informacion precacheada en modal de registro detalle tareas 
    this.setState({
      visible: false,
      visiblePausa: false,
      mostrarMD: false,
      choiseMD: false,
      cantidadhoras: null,
      subtarea: null,
      typetask: "",
      observaciones: null,
      typehour: null,
      errorObserv: null,
      fecha: moment().format("YYYY-MM-DD"), // a usar en el momento del submit
      hora: moment().format("HH:mm") // a usar en el momento del submit
    });
  };
  
  handleChange = (event) => {
    //cada campo que cambia se asigna automaticamente, si el campo es cantidad de horas, usamos expresiones regulares
    //para limitar la introduccion del usuario y no permitir otra cosa que no sea 0-9 luego un . y el unico decimal aceptado un 5
    let name = event.target.name;
    if (name ==='cantidadhoras'){
      var re = /^([0-8]{1}(\.[5]{1})?)$/; 
      let inputHour = event.target.value;
      let comprobacion = inputHour.split(".");
      
      if (comprobacion.length > 1 && comprobacion[1] === ""){
        this.setState({ cantidadhoras: event.target.value })
      }
      if(inputHour === ""){
        this.setState({ cantidadhoras: event.target.value })
      }
      var OK = re.exec(inputHour);
      if (!OK) {
        //console.log("esta colocando mal")
      } else {
        this.setState({ cantidadhoras: event.target.value })
      }
    }else{
      this.setState({ [name]: event.target.value })
    }
  }
  
  iniciarJornada = () => {
    //Inicio de jornada, no se hace ningun calculo, solo armamos el Json y lo enviamos a guardado
    this.setState({ loading: true });
    
    let data = {
      empleado_id: this.state.empleado_id,
      fecha: moment().format("YYYY-MM-DD"),
      hora: moment().format("HH:mm"),
      tipo_fichaje: "Inicio de Jornada",
      tiempoAcumulado: "0",
      longitud: "",
      latitud: "",
      isActive: 1
    };
    this.setState({parcial:true , loading: true });
    this.save(data, insertFichaje);
  };
  detieneJornada = async () => {
    debugger;
    //finaliza la jornada, calcula toda la jornada, comprueba horas y tipos, levanta modal resumen
    this.setState({ loading: true, choiseMD: false });
    let current = new Date();
    let tiempoApi = this.state.tiempoApi;
    let tiempot = null;
    // comprobamos si ya se ha solicitado la informacion al servidor, si es asi estará precacheada en --state.calculaTemp[0]
    if(tiempoApi){
      let lastCall = tiempoApi.getTime();
      let actuCall = current.getTime();
      if(lastCall< actuCall){
        tiempot = await this.calculaJornada();
      }else{
        tiempot = this.state.calculaTemp[0];
      }
    }else{
      tiempot = await this.calculaJornada();
    }

    let imputable = tiempot.horas +( tiempot.minutos / 60 );
    imputable = imputable.toFixed(1);
    let declaracion = this.state.dataSource;
    let pendObserv = 0;
    let pendOtro = 0;
    let errorPend = false;
    let errorOtro = false;
    // si hay horas declaradas las recorremos y comprobamos las horas que no tengan observacion ya bien sean 
    //pendientes de justificar o al proyecto OTROS 
    // recorremos para comprobar todas las horas declaradas
    let declaradasOrd = 0;
    let declaradasJus = 0;
    
    this.setState({
      declaradasOrd,
      declaradasJus,
    });
    if(declaracion){
      declaracion.map(x => {
        if (x.typehour === 'Ord' || x.typehour === 'Aus') {
          declaradasOrd = parseFloat(declaradasOrd)+parseFloat(x.Horas);
        }else if (x.typehour === 'Just') {
          declaradasJus = parseFloat(declaradasJus)+parseFloat(x.Horas);
        }
        if ((x.typehour === 'Just' || x.typehour === 'Aus') &&  (x.observaciones === '' || x.observaciones === null)) {
          pendObserv++ ;
        }
        if(x.idProyect === 108 && (x.observaciones ==='' || x.observaciones === null)){
          pendOtro++ ;
        }
      });
    }
    if(pendObserv > 0){
      errorPend = true;
    }
    if(pendOtro > 0){
      errorOtro = true;
    }
    this.setState({mostrarMD : true, calculadaH : tiempot.horas, calculadaM: tiempot.minutos, inicioJ: tiempot.inicio, imputable: imputable,
                   loading: false, pendObserv: errorPend, pendOtro: errorOtro, declaradasOrd , declaradasJus});
  }
  chooseAction = async () => {
    //Levanta modal finalizar o pausa
    this.setState({choiseMD : true});
  }

  listarMotivosPausa = async () => {
    let motivos = await get(getReasonPause, this.props.session.auth_token);//debugger;
    this.setState({ motivosPausa: motivos.data });
  }

  calculaJornada = async () => {
    //debugger;
    //funcion para tomar fichajes y calcular completamente los tiempos trabajados con pausas
    let fichajes = [];
    let ultimosact = [];
    let calculaTemp = [];
    let conteoPausas = 0;
    const {empleado_id, auth_token} = this.props.session;
    this.setState({ loading: true });
    ultimosact = await post({ empleado_id }, getLastFichaje, auth_token);//debugger;
    if (ultimosact.data){
      this.setState({ ultimo: ultimosact.data.ultimo, inicios: ultimosact.data.inicios });
      fichajes = ultimosact.data.fichajes; // getFichajeWithData;
    } 
    this.setState({ loading: true });
    //comprobamos que el Token aun esta activo <12 horas
    // si esta inactivo (Unauthenticated.) enviamos a pagina de login
    // este comportamiento debe ser migrado a App.index.js
    // comprobamos el token con la llamada de selectInfoDash y enviamos la info a este componente por medio de un prop (PENDIENTE)
    if(ultimosact ===  "Unauthenticated." ){
      localStorage.clear();
      this.props.session.isLoggedIn = true;
      this.props.session.auth_token = null;
      window.location.href = "/signin"
    } else {
      
      this.setState({ loading: true });
      let current = new Date();
      let tiempoApi = new Date(current.getTime() + 1*60000);
      //debugger;
      let ultimo_fichaje = this.state.ultimo ? this.state.ultimo : null;
      let tipo_fichaje =null ;
      let horaInicio =null ;
      let fechaUltimo =null ;
      let tiempo_acumulado =null ;
      tiempo_acumulado =null ;
      let suma_acumulados =null ;
      let i =null ;
      if(ultimo_fichaje){
        tipo_fichaje = ultimo_fichaje.tipo_fichaje;
        horaInicio = ultimo_fichaje.hora;
        fechaUltimo = moment( ultimo_fichaje.fecha + " " + horaInicio ).toDate();
        tiempo_acumulado = moment.duration(moment() - fechaUltimo).asMinutes();
        tiempo_acumulado = Math.floor(tiempo_acumulado);
        suma_acumulados = tiempo_acumulado;
        i = ultimo_fichaje.tipo_fichaje;
      }
      
      let fecha =  moment().format("YYYY-MM-DD");
      let hora = moment().format("HH:mm");
      //CALCULAR LA SUMA DE TIEMPOS ACUMULADOS DESDE EL ULTIMO INICIO DE JORNADA
      
      // todos2.map(x => {
      //   activos2.push(x);
      // });
      // let fichajes = activos2.reverse();
      //console.log("este es i: "+i);
      if(tipo_fichaje === "Fin de Jornada"){
        suma_acumulados = 0;
      }else{
        fichajes.map(x => {
          if (tipo_fichaje != "Inicio de Jornada" && x.tiempoAcumulado >= 0) {
            suma_acumulados += Math.floor(x.tiempoAcumulado);
            i = x.tipo_fichaje;
          }else if (x.tiempoAcumulado < 0){
            conteoPausas += Math.floor(x.tiempoAcumulado);
          }
        });
      }

      let tiempo = 0;
      let entrada = this.state.inicioJornada;
      let duracion = this.state.duracionJornada;
      let fechaComp = moment( fecha + " " + entrada ).toDate();
      let fechaCompS = moment(fechaComp).add(duracion, 'hours');
      let tiempOrdi =  moment.duration(fechaCompS - fechaComp ).asMinutes();
      if(tiempOrdi < suma_acumulados){
        //9 horas 540  u 8.5 horas 510 
        //console.log("tiene una jornada mayor a la imputable 8,5 horas");
        tiempo = tiempOrdi;
      }else{
        //console.log("tiene una jornada menor a la ordinaria");
        tiempo = suma_acumulados;
      }
      let horas = Math.floor(tiempo / 60);
      let minutos = Math.floor(tiempo % 60);

      calculaTemp.push({
                        "tiempo_acumulado": tiempo,
                        "suma_acumulados": tiempo,
                        "horas":horas,
                        "minutos": minutos,
                        "fecha": fecha,
                        "hora":hora,
                        "inicio": horaInicio
      })
      this.setState({
                    fichajes: fichajes,
                    tiempoApi: tiempoApi,
                    calculaTemp: calculaTemp,
                    tiempoTotal: suma_acumulados,
                    pausas: conteoPausas
      });

      return {"tiempo_acumulado": tiempo,
              "suma_acumulados": tiempo,
              "horas":horas,
              "minutos": minutos,
              "fecha": fecha,
              "hora":hora,
              "inicio": horaInicio};

      }
  }
  detenerJornada = async () => {
    //DETENER EL RELOJ Y CALCULA EL TIEMPO ACUMULADO
    this.setState({ loading: true });
    debugger;
    // MOVIDO A FUNCION CALCULA JORNADA modulacion1
    let calculada = await this.calculaJornada();
    let color = null;
    let tiempo_acumulado = calculada.tiempo_acumulado;
    let fecha = calculada.fecha;
    let hora = calculada.hora;
    //CALCULAR LA SUMA DE TIEMPOS ACUMULADOS DESDE EL ULTIMO INICIO DE JORNADA
    let suma_acumulados = calculada.suma_acumulados;
    let i = this.state.ultimo.tipo_fichaje;
    let horas = calculada.horas;
    let minutos = calculada.minutos;

    this.setState({
      sumaTiempoTotales: Math.floor(suma_acumulados), // en minutos
      tiempo_acumulado: tiempo_acumulado,
      horasRealizadas: Math.floor(suma_acumulados), // en minutos
      fecha:fecha, // a usar en el momento del submit
      hora: hora, // a usar en el momento del submit
      iconb: "fas fa-play",
      //cardColor: color,
      titlebtn: "Iniciar Jornada",
      actions: this.iniciarJornada,
      tiempoAcumulado_ : '00:00'
    });

  //consultar si el dataSource tiene info, sino generar una tarea para el proyecto por defecto this.state.proyectDef
    let tareasD = this.state.dataSource;
    debugger;
    if(!tareasD){
      tareasD = [];
      horas = (tiempo_acumulado / 60);
      tareasD.push({"id":1,  "nombreProyecto":this.state.proyectDef.nombre,
                    "Horas":horas, "typehour" : "Ord",
                    "typetask":"", "subtarea":"","observaciones":"Cerrado Automatico",
                    "idProyect" : this.state.proyectDef.id});
    }
    let data = {
      empleado_id: this.state.empleado_id,
      observaciones: this.state.observaciones,
      fecha: moment().format("YYYY-MM-DD"),
      hora: moment().format("HH:mm"),
      tipo_fichaje: "Fin de Jornada",
      tiempoAcumulado: tiempo_acumulado,
      isActive: 1,
      tareas: tareasD
    };
    //console.log("envio : ", data);
    window.localStorage.setItem("dataSource", []);
    this.setState({parcial:false,mostrarMD:false, dataSource: [], loading:true});
    this.save(data, insertFichaje);
    
  };
  test = (oj) =>{
    //cambiar nombre de funcion para hacerlo mas reconocible para otros 
    // funcion de eliminacion de tareas y reconocimiento de registros
    // acutalizamos las horas declaradas en base a lo que se elimina
    const rastreo = this.state.dataSource;
    let borrador = this.state.dataSource;

    let { declaradasOrd, declaradasJus} = this.state;
    let edit = rastreo.splice(rastreo.findIndex(({id}) => id == oj), 1)[0];

    
    if(edit.typehour === 'Ord'){
      declaradasOrd = parseFloat(declaradasOrd)-parseFloat(edit.Horas);
    }else if(edit.typehour === 'Just'){
      declaradasJus = parseFloat(declaradasJus)-parseFloat(edit.Horas);
    }
    
    borrador.push(edit);
    borrador.splice(borrador.findIndex(({id}) => id == oj), 1);

    let prob1 = JSON.stringify(borrador);
    prob1 = prob1 === '[]' ? null : prob1;
    

    window.localStorage.setItem("dataSource", prob1 );
    this.setState({
      dataSource : borrador,
      declaradasJus,
      declaradasOrd
    });
  }
  clearForm(){
    this.setState({
      proyectselected: "Select",
      horasProyect: null
    })
  }
 /*  INCLUSION DE PAUSAS */

  pausarJornada = () => {
    // inicio de pausa, al igual que el inicio de jornada solo se guarda, pero 
    // se toma el tiempo acumulado desde el ultimo inicio de jornada / fin de pausa
    // se asigna al registro para su contabilizacion posteriormente y se envia al back
    this.setState({ loading: true });
    let ultimo_fichaje = this.state.ultimo;
    let fechaUltimo = moment(ultimo_fichaje.fecha +" "+ ultimo_fichaje.hora).toDate()

    let tiempo_acumulado = moment.duration(moment() - fechaUltimo).asMinutes() 

    let data = {
        empleado_id: this.state.empleado_id,
        fecha: moment().format("YYYY-MM-DD"),
        hora: moment().format("HH:mm"),
        tipo_fichaje: "Inicio de Pausa",
        tiempoAcumulado: Math.floor(tiempo_acumulado),
        longitud: "",
        latitud: "",
        isActive: 1,
    }
    this.setState({choiseMD: false, loading: true});
    this.save(data, insertFichaje)
  }

  reiniciarJornada = async () => {
    //reiniciamos la jornada, tomamos el tiempo total desde el inicio de la pausa y lo agregamos al registro para contabilizar 

    this.setState({loading: true});
    let ultimo_fichaje = this.state.ultimo;
    let fechaUltimo = moment(ultimo_fichaje.fecha +" "+ ultimo_fichaje.hora).toDate()
    let tiempo_acumulado = moment.duration(moment() - fechaUltimo).asMinutes() 

    let tiempo_floor = -Math.floor(tiempo_acumulado)

    let data = {
        empleado_id: this.state.empleado_id,
        fecha: moment().format("YYYY-MM-DD"),
        hora: moment().format("HH:mm"),
        tipo_fichaje: "Fin de Pausa",
        tiempoAcumulado: tiempo_floor,
        longitud: "",
        latitud: "",
        isActive: 1,
    }
    this.setState({choiseMD: false, loading: true});
    this.save(data, insertFichaje)
  }

 /* FIN INCLUSION DE PAUSAS */

  /* MOSTRAR MODALS */

  showModal = () => {
    this.setState({ loading: true, choiseMD: false, mostrarMD: true });
  }

  showModalPausa = () => {
    console.log("DEBE ABRIR MODAL")
    this.setState({ loading: true, visiblePausa: true});
  }

  handleOkPausa = async () => {

    this.setState({ loading: true });
    let ultimo_fichaje = this.state.ultimo;
    let fechaUltimo = moment(ultimo_fichaje.fecha +" "+ ultimo_fichaje.hora).toDate()

    let tiempo_acumulado = moment.duration(moment() - fechaUltimo).asMinutes() 

    let data = {
        empleado_id: this.state.empleado_id,
        fecha: moment().format("YYYY-MM-DD"),
        hora: moment().format("HH:mm"),
        tipo_fichaje: "Inicio de Pausa",
        motivoPausa: this.state.motivoPausa,
        motivoPausaText: this.state.motivoPausaText,
        tiempoAcumulado: Math.floor(tiempo_acumulado),
        longitud: "",
        latitud: "",
        isActive: 1,
    }
    this.setState({visiblePausa: false, loading: true});
    this.save(data, insertFichaje)
    this.setState({motivoPausa: ""});
  };

  handleSelectChangeTypePause = (value) => {
    this.setState({motivoPausa:value});
  }


  /* FIN MOSTRAR MODALS */


/* Imputacion carrito de compra  */
  cachear = () => {
    //funcion para guardar informacion de imputacion de horas a base de datos 
    // aun pendiente de discucion, en peligro está el performance del servidor, que tiene muy pocos recursos (considero)
    // no esta desarrollado no esta hecho
    const {dataSource} = this.state;
    let probandolinea = JSON.stringify(dataSource);//informacion que tenemos en localstorage del navegador, y la convertimos a string para su guardado en bbdd
    let data = {
      empleado : this.state.empleado_id,
      linea : probandolinea
    }
    console.log(data);
    this.save(data, saveDraft)
    
  }
/* Fin Imputacion carrito de compra  */
  async save(data, url) {
    debugger;
    //metodo de guardado general, comprobamos cuando hay que enviar mensaje de guardado exitoso
    //con tick() volvemos a calcular toda la informacion necesaria para el funcionamiento luego de haber guardado el registro 
    let urlcheck = url.split("api/").pop();
    try {
      let token = this.props.session.auth_token;
      this.setState({ loading: true });
      let resp = await post(data, url, token);
      this.setState({ loading: true });
      if (resp.success) {
        if(urlcheck == "insertFichaje"){
          this.success(data);
          await this.calculaJornada();
        }
        this.setState({ loading: true });
        
        this.setState({ loading: true });
        this.tick();

      }else{
        debugger;
        localStorage.clear();
        this.props.session.isLoggedIn = false;
        this.props.session.auth_token = null;
        window.location.href = "/signin"
        //alert("hubo un error al guardar contacte a sistemas");
      }

    } catch (Error) {
      console.error(Error);
    }
  }
  success = data => {
    message.success( `${data.tipo_fichaje} a las ${moment(data.dateTime).format("HH:mm:ss")}`, 1 );
  };
  render() { // donde se construye todo el layout
    if(!this.state.fichajes) return(<div style={{width: "100%", heigth: "100%"}}><Spin spinning={loading}></Spin></div>);
    if(this.state.unauthorized) return null; //informacion principal necesaria, si el token o redireccion falla, pues mostramos pantalla vacia
    let TiempoTotalTrabajado = "";
    let horas = 0;
    let minutos = 0;

    if (this.state.horasRealizadas >= 60 || this.state.horasRealizadas <= -60) {
      horas = Math.floor(this.state.horasRealizadas / 60);
      minutos = Math.floor(this.state.horasRealizadas % 60);
      
      TiempoTotalTrabajado = horas + "h " + minutos + "m ";
    } else {
      TiempoTotalTrabajado =
        Math.floor(this.state.horasRealizadas) + " minutos";
    }
    let sumaTiempoTotales = "";
    if (
      this.state.sumaTiempoTotales >= 60 ||
      this.state.sumaTiempoTotales <= -60
    ) {
      let horas = Math.floor(this.state.sumaTiempoTotales / 60);
      let minutos = Math.floor(this.state.sumaTiempoTotales % 60);
      
      sumaTiempoTotales = horas + "h " + minutos + "m ";
    } else {
      sumaTiempoTotales = Math.floor(this.state.sumaTiempoTotales) + " minutos";
    }
    
    const dashColumns = [
      {
        title: "Proyecto",
        dataIndex: "nombreProyecto",
        key: "nombreProyecto"
      },
      {
        title: "Horas",
        dataIndex: "Horas",
        key: "Horas"
      },
      {
        title: "Tipo.H ",
        dataIndex: "typehour",
        key: "typehour"
      },
      {
        title: "Acciones",
        dataIndex: "id",
        key: "id",
        fixed: "right",
        render: (value, dataSource) => (
          <span>
            <Button onClick={() => this.test(dataSource.id)} type="danger" icon="delete" size="small" style={{ marginBottom: "0px"}}/>            
            <Button onClick={() => this.agregarTarea(dataSource.id)} type="primary" icon="search" size="small" style={{ marginBottom: "0px"}}/>
          </span>
        )
      }
    ];
    let opciones = null;
    if (this.state.tipoProyectos) {
      opciones = this.state.tipoProyectos.map( x => <Option key={x.key} id={x.id} value={x.id} required={true}>{x.nombre} </Option>)
    }

    const TipoHorasProM = this.state.tipoHProy ? this.state.tipoHProy.map( x => <Option key={x.value} id={x.id} value={x.value} >{x.nombre}</Option>) : null
    const TipoTareasProM = this.state.tareasProy ? this.state.tareasProy.map( x => <Option key={x.value} id={x.id} value={x.value} >{x.nombre}</Option>) : null
    const Habilitado = this.state.declaradas < (parseFloat(this.state.calculadaH)+parseFloat(this.state.calculadaM / 60 )) ? true : false;
    const {loading} = this.state;

    const MotivosPausaShow = this.state.motivosPausa.map( x => <Option key={x.id} id={x.id} value={x.nombre} >{x.nombre}</Option>)
    
    return (
      <div>
        <Spin spinning={loading}>
        <Row>
          {/*<Col  xs={{span: 24, offset:0 }} lg={{span:8, offset:8}} xl={{span:8, offset:8}}   className="gx-text-left">
            <IconWithTextCard
              cardColor={`${this.state.cardColor}`}
              title={this.state.titlebtn}
              actions={this.state.actions}
              icon={this.state.iconb}
            />
          </Col>*/}
          <Col  xs={{span: 24, offset:0 }} lg={{span:8, offset:8}} xl={{span:6, offset:2}}   className="gx-text-left">
            <IconWithTextCard
              cardColor={`${this.state.btnColorPlay}`}
              title={this.state.btnTitlePlay}
              actions={this.state.btnActionPlay}
              icon={"fas fa-play"}
            />
          </Col>
          <Col  xs={{span: 24, offset:0 }} lg={{span:8, offset:8}} xl={{span:6, offset:2}}   className="gx-text-left">
            <IconWithTextCard
              cardColor={`${this.state.btnColorPause}`}
              title={this.state.btnTitlePause}
              actions={this.state.btnActionPause}
              icon={"fas fa-pause"}
            />
          </Col>
          <Col  xs={{span: 24, offset:0 }} lg={{span:8, offset:8}} xl={{span:6, offset:2}}   className="gx-text-left">
            <IconWithTextCard
              cardColor={`${this.state.btnColorStop}`}
              title={this.state.btnTitleStop}
              actions={this.state.btnActionStop}
              icon={"fas fa-stop"}
            />
          </Col>
          <Col  xs={{span: 24, offset:0 }} lg={{span:8, offset:8}} xl={{span:6, offset:2}}   className="gx-text-left">
            <span style={{ color: 'white', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontSize: '3rem', textAlign: 'center', display: 'block', marginTop: 5, fontWeight: "bold" }}>
              {this.state.tiempoAcumulado_}
            </span>
          </Col>
          
        </Row>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-order-sm-1"
            >
              <Card  className="gx-card" style={{display: this.state.parcial ? "":"none"}}> {/**/}
                <Form layout="inline">
                  <Form.Item label="Proyecto ">
                    <Select value={this.state.proyectselected} style={{ color: "black", minWidth: "30vh"}} onChange={this.handleSelectChangeProyect}>
                      {opciones}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Horas " name="canthoras" >
                    <Input
                      required
                      placeholder={"Introduzca Horas"}
                      type="text"
                      className="center-block"
                      value={this.state.horasProyect}
                      maxLength={3}
                      onChange={this.changehour}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      block
                      type="primary"
                      icon="plus"
                      onClick={this.agregar}
                      htmlType="submit"
                    >
                      Agregar Tarea
                    </Button>
                  </Form.Item>
                  {/* 
                    <Form.Item>
                      <Button
                        block
                        type="success"
                        icon="plus"
                        onClick={this.cachear}
                        htmlType="submit"
                      >
                        Guardar Borrador
                      </Button>
                    </Form.Item> */
                  }
                </Form>
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={this.state.dataSource} 
                  columns={dashColumns}
                  pagination={false} 
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              className="gx-order-sm-1"
            >
               <FichajeLog
                empleado_id={this.props.session.empleado_id}
                contexto="empleado"
                dataFichaje={this.state.fichajes}
              /> 
            </Col>
          </Row>
        </Spin>
        <Modal
          title="Detalle de Horas"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="Confirmar" type="primary" onClick={this.handleOk}>
              Confirmar
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Cancelar
            </Button>
          ]}
        >
          <Form layout="vertical">
            {/* TABLA TIEMPOS*/}

              <p style={{color:"#02807F"}}>Proyecto:    <span style={{color:"#4a4a4a"}}>{this.state.proyectoSelecc}</span></p>
              
              <Form.Item label="Cantidad Horas ">
                    <Input
                      name="cantidadhoras"
                      type="text"
                      className="center-block"
                      maxLength={3}
                      required={true}
                      value={this.state.cantidadhoras}
                      onChange={this.handleChange}
                    />
              </Form.Item>
              <Form.Item label="Tipo Horas">
                <Select  value={this.state.typehour} style={{ color: "black" }} onChange={this.handleSelectChangeTypeH}>
                  {TipoHorasProM}
                </Select>
              </Form.Item>
              <Form.Item label="Tarea">
                {/* <Select  value={this.state.typetask} style={{ color: "black" }} onChange={this.handleSelectChangeTypeTsk}>
                        <Option value="Select">Seleccione</Option>
                        {TipoTareasProM}
                </Select> */}
                <Input
                  name="typetask"
                  type="text"
                  className="center-block"
                  maxLength={70}
                  value={this.state.typetask}
                  required={true}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item label="SubTarea ">
                    <Input
                      name="subtarea"
                      type="text"
                      className="center-block"
                      maxLength={70}
                      value={this.state.subtarea}
                      required={true}
                      onChange={this.handleChange}
                    />
              </Form.Item>
              <Form.Item label="Observaciones ">
                <TextArea
                  name="observaciones"
                  rows={2}
                  className="center-block"
                  maxLength={140}
                  required={true}
                  value={this.state.observaciones}
                  onChange={this.handleChange}
                />
                {/*{
                  this.state.errorObserv ?     
                  <Alert className="gx-mt-2" message="Debe Justificar la hora en campo Observaciones" type="error" showIcon />
                  : null
                }*/}
              </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Fin de Jornada"
          visible={this.state.mostrarMD}
          onOk={this.detenerJornada}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="Confirmar" 
                  //type={"primary"}
                  type={(this.state.pendObserv || this.state.pendOtro) ? "grey" : "primary"}
                  disabled={this.state.pendObserv || this.state.pendOtro }
                  onClick={this.detenerJornada}>
              Confirmar
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Cancelar
            </Button>
          ]}
        >
          <Form layout="vertical">
            { this.state.calculadaH < 8 && this.state.calculadaM < 30 ? 
            <Alert
              className="gx-mt-1 gx-mb-2"
              message="La jornada trabajada es inferior a la jornada ordinaria."
              type="warning"
              showIcon
            />
            :
            this.state.imputable >= this.state.duracionJornada && this.state.declaradasOrd === 0 && this.state.proyectDef ? 
              <Alert
                className="gx-mt-1 gx-mb-2"
                message={`No tiene horas declaradas, su jornada sera imputada al Proyecto ${this.state.proyectDef.nombre} ` }
                type="error"
                showIcon
              />: null }  
            {
              this.state.declaradasOrd > 0 && this.state.declaradasOrd < (this.state.calculadaH + (this.state.calculadaM / 60)) ? 
              <Alert
                className="gx-mt-1 gx-mb-2"
                message={"Las horas ordinarias imputadas son inferiores a la jornada trabajada." }
                type="warning"
                showIcon
              /> : null
            }

            {this.state.pendObserv  ? 
            <Alert
              className="gx-mt-1 gx-mb-2"
              message="Tiene horas pendientes de Justificar sin Observación."
              type="error"
              showIcon
            />
            :
            null }
            {this.state.pendOtro  ? 
            <Alert
              className="gx-mt-1 gx-mb-2"
              message="Tiene horas al proyecto OTROS sin Justificar/Observación."
              type="error"
              showIcon
            />
            :
            null } 
            <table style={{border:"none", width:"100%"}}>

              <tbody>
                <tr>
                  <td>Inicio de Jornada:</td>
                  <td>{this.state.inicioJ}</td>
                </tr>
                <tr>
                  <td>Jornada Ordinaria:</td>
                  <td>{this.state.duracionJornada} H</td>
                </tr>
                <tr>
                  <td>Pausas:</td>
                  <td>{(this.state.pausas)*-1 } Min</td>
                </tr>
                <tr>
                  <td>Imputable:</td>
                  <td> {this.state.imputable} H</td>
                </tr>
                <tr>
                  <td>Jornada Total:</td>
                  <td> {(this.state.tiempoTotal /60 ).toFixed(1)} H</td>
                </tr>
                <tr>
                  <td colSpan={2} style={{visibility: "hidden"}}> a</td>
                </tr>
                <tr>
                  <td colSpan={2} style={{fontWeight: "bold", textAlign: "center"}}>Imputación</td>
                </tr>
                <tr>
                  <td>Horas Ordinarias:</td>
                  <td>{this.state.declaradasOrd} H</td>
                </tr>
                {this.state.declaradasJus > 0 ? 
                  <tr>
                    <td>Horas Pendientes Justificación:</td>
                    <td>{this.state.declaradasJus} H</td>
                  </tr>
                  : null}
                {(this.state.declaradasOrd + this.state.declaradasJus)  < this.state.imputable ?
                  <tr>
                    <td>Horas Pendientes Imputación:</td> 
                    <td>{(this.state.imputable - (this.state.declaradasOrd + this.state.declaradasJus)).toFixed(1)} H</td>
                  </tr>
                  : null}
                 
                  
                </tbody>
              </table>
              

          </Form>
        </Modal>
        <Modal
          title="¿Que desea hacer?"
          visible={this.state.choiseMD}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="Pausar" type="primary"
                  onClick={this.pausarJornada}
                  //disabled={ this.state.jornadaCumplida }
                  style={{display: this.state.jornadaCumplida ? "none":""}}
                  >
              Pausar
            </Button>,
            <Button key="Finalizar" type="primary"
                  onClick={this.detieneJornada}
                   >
              Finalizar
            </Button>
          ]}
        >
        <div>
          Por Favor, seleccione si desea Pausar su Jornada o Finalizar su Jornada Completamente
        </div>
        </Modal>
        <Modal
          title="Pausa Jornada"
          visible={this.state.visiblePausa}
          onOk={this.handleOkPausa}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="Confirmar" type="primary" onClick={this.handleOkPausa}>
              Confirmar
            </Button>,
            <Button key="back" onClick={this.handleCancel}>
              Cancelar
            </Button>
          ]}
        >
          <Form layout="vertical">
            {/* TABLA TIEMPOS*/}

              <p style={{color:"#02807F"}}>Especifique motivo de pausa.</p>
              <Form.Item label="">
                 <Select  value={this.state.motivoPausa} style={{ color: "black" }} onChange={this.handleSelectChangeTypePause}>
                        <Option value="Select">Seleccione</Option>
                        {MotivosPausaShow}
                </Select> 
              </Form.Item>
              <Form.Item label="">
                <TextArea
                  name="motivoPausaText"
                  rows={2}
                  className="center-block"
                  maxLength={140}
                  required={true}
                  value={this.state.motivoPausaText}
                  onChange={this.handleChange}
                />
              </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, {})(Dashboard);