import React from "react";
//import { Link } from "react-router-dom";
import Widget from "components/Widget/index";

const IconWithTextCard = ({
  cardColor,
  icon,
  title,
  desc,
  title2,
  desc2,
  subTitle,
  iconColor,
  actions
}) => {
  return (
    <Widget styleName={`gx-card-full gx-p-3 gx-bg-${cardColor} gx-text-white cursor-pointer`} execute = {actions}>
      <div className="gx-media gx-align-items-center gx-flex-nowrap">
        <div className="gx-mr-2 gx-mr-xxl-3">
          <i
            className={`${icon} gx-text-${iconColor}`}
            style={{ fontSize: "30px", marginRight: "10px"}}
          />
        </div>
        <div className="gx-media-body gx-text-center">
          <h1 className="gx-fs-xxl gx-font-weight-semi-bold gx-mb-1 gx-text-white">
            {title}
            <span style={{ fontSize: "14px", fontWeight: 300 }}> {desc} </span>
            {title2}
            <span style={{ fontSize: "14px", fontWeight: 300 }}> {desc2}</span>
          </h1>
          <p className="gx-mb-0">{subTitle}</p>
        </div>
      </div>
    </Widget>
  );
};

export default IconWithTextCard;
